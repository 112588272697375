export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim());
}

export enum COMPONENT_ID { // from dev center
  GROUP_LIST = 'groups',
  GROUP_PAGE = 'group-page',
  GROUP = 'group',
  GROUPS_AREA = 'groups_area',
}

// EditorX returns 'False' if formFactor is not 'Desktop'
// Editor returns 'Mobile' if formFactor is not 'Desktop'
export function isMobileByFormFactor(formFactor: string): boolean {
  return formFactor !== 'Desktop';
}

/**
 * checks if an object has object type not null
 * @param o
 */
export function isObject(o: any) {
  return o === Object(o);
}

export function isMobileSettings() {
  return window.Wix.Utils.getDeviceType() === 'mobile';
}

export function isUndefinedOrTrue(value: unknown) {
  return value === undefined || !!value;
}

export function clamp(value: number, min: number, max: number) {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  }

  return value;
}
