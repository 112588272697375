import { keyBy } from 'lodash';

import {
  configureApps,
  listApps,
} from '@wix/ambassador-social-groups-v1-group-app/http';
import {
  GroupApp,
  GroupAppKey,
} from '@wix/ambassador-social-groups-v1-group-app/types';
import { deleteGroup } from '@wix/ambassador-social-groups-v2-group/http';
import type {
  Group,
  GroupSettings,
} from '@wix/ambassador-social-groups-v2-group/types';
import { listRules } from '@wix/ambassador-social-groups-v2-group-rules/http';
import { getActivityStats } from '@wix/ambassador-social-groups-v1-activity-stats/http';
import { resetUpdatesCounter } from '@wix/ambassador-social-groups-v2-group-updates/http';
import { getJoinRequirements } from '@wix/ambassador-social-groups-v2-join-group-request/http';
import * as notificationSettings from '@wix/ambassador-social-groups-v1-notification-settings/http';
import type { GetJoinRequirementsRequest } from '@wix/ambassador-social-groups-v2-join-group-request/types';
import { ViolationType } from '@wix/ambassador-social-groups-v2-join-group-request/types';
import type {
  GetNotificationSettingsRequest,
  UpdateNotificationSettingsRequest,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';
import {
  createOrReplaceAllMembershipQuestions,
  listMembershipQuestions,
} from '@wix/ambassador-social-groups-v2-membership-question/http';
import { CreateOrReplaceAllMembershipQuestionsRequest } from '@wix/ambassador-social-groups-v2-membership-question/types';
import { JoinRequest } from '@wix/ambassador-social-groups-v2-group-member/types';
import { queryEventsV2 } from '@wix/ambassador-events-v1-event/http';
import {
  EventFieldset,
  SortOrder,
} from '@wix/ambassador-events-v1-event/types';

import * as groups from 'api/groups';
import * as membership from 'api/membership';

import { GroupsRequestSort } from 'settings/consts';

import * as application from 'store/application';
import {
  selectCurrentUser,
  selectShareProfileConsent,
} from 'store/application/selectors';
import { selectGroupName } from 'store/groups/selectors';

import { createAsyncThunk, is404 } from '../utils';

import { ENABLED_TABS, ESortOptions, TABS_WHITELIST } from './constants';
import type {
  IJoinRequirements,
  IQueryGroupsParams,
  IUpdateGroupInfoParams,
} from './types';

export const resetActivityCounter = createAsyncThunk(
  'group:resetActivityCounter',
  async (groupId: string, thunkAPI) => {
    return thunkAPI.extra.httpClient.request(resetUpdatesCounter({ groupId }));
  },
  {
    hideErrorMessage: true,
  },
);

export const fetchActivity = createAsyncThunk(
  'group:activity:fetch',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      getActivityStats({ groupId }),
    );

    return data;
  },
  {
    formatError: (t) => t('groups-web.toast.error.groups.activity.fetch'),
  },
);

export const fetchRules = createAsyncThunk(
  'group:rules:fetch',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      listRules({ groupId }),
    );

    return data.rules ?? [];
  },
  {
    formatError: (t) => t('groups-web.toast.error.groups.rules.fetch'),
  },
);

export const updateGroupInfo = createAsyncThunk(
  'group:updateInfo',
  async function (params: IUpdateGroupInfoParams, thunkAPI) {
    const { groupId, ...groupChanges } = params;
    const { translations } = thunkAPI.extra;

    const { data } = await thunkAPI.extra.httpClient.request(
      groups.updateGroup(groupId, groupChanges),
    );

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        message: translations.t('groups-web.toast.group.update'),
      }),
    );

    return data.group;
  },
  {
    formatError: (t) => t('groups-web.toast.error.group.update'),
  },
);

export const create = createAsyncThunk(
  'groups:create',
  async function (params: Group, thunkAPI) {
    const { translations } = thunkAPI.extra;

    const { data } = await thunkAPI.extra.httpClient.request(
      groups.create(params),
    );

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        message: translations.t('groups-web.toast.success.group.create', {
          name: data.group.name,
        }),
      }),
    );

    return data.group;
  },
  {
    formatError(t, params) {
      return t('groups-web.toast.error.group.create', {
        name: params.name,
      });
    },
  },
);

export const updateGroupSettings = createAsyncThunk(
  'group:updateGroupSettings',
  async function (
    params: { groupId: string; settings: GroupSettings },
    thunkAPI,
  ) {
    const { groupId, settings } = params;

    const { data } = await thunkAPI.extra.httpClient.request(
      groups.updateGroup(groupId, {
        settings,
      }),
    );

    return data.group.settings;
  },
  {
    formatError: (t) => t('groups-web.toast.error.group.update'),
  },
);

export const updateGroupApps = createAsyncThunk(
  'group:updateGroupApps',
  function (params: { groupId: string; apps: GroupApp[] }, thunkAPI) {
    return thunkAPI.extra.httpClient.request(
      configureApps({
        groupId: params.groupId,
        apps: params.apps,
      }),
    );
  },
  {
    formatError(t) {
      return t('groups-web.toast.error.groupApps.update');
    },
  },
);

export const query = createAsyncThunk(
  'groups:query',
  async function (params: IQueryGroupsParams, thunkAPI) {
    const {
      title,
      partition,
      limit = 5,
      offset = 0,
      sort = GroupsRequestSort.RECENT_ACTIVITY,
    } = params;

    const order =
      sort === GroupsRequestSort.NAME ? SortOrder.ASC : SortOrder.DESC;

    const { data } = await thunkAPI.extra.httpClient.request(
      groups.query({
        partition,
        paging: { offset, limit },
        sort: [{ fieldName: ESortOptions[sort], order }],
        filter: !title
          ? undefined
          : { lowerCaseTitle: { $contains: title.toLowerCase() } },
      }),
    );

    return data;
  },
);

export const fetchGroup = createAsyncThunk(
  'group:fetch',
  async function (
    params: { groupIdOrSlug: string; autoInviteId?: string },
    thunkAPI,
  ) {
    const { groupIdOrSlug, autoInviteId } = params;

    const response = await thunkAPI.extra.httpClient.request(
      groups.getGroup(groupIdOrSlug, autoInviteId),
    );

    const { group } = response.data;

    if (!group) {
      throw new Error('Group not found');
    }

    const { data } = await thunkAPI.extra.httpClient.request(
      listApps({
        groupId: group.id as string,
      }),
    );

    const applications = keyBy(data.apps, 'key');

    const apps: GroupApp[] = TABS_WHITELIST.map((key) => ({
      key,
      installed: ENABLED_TABS.includes(key),
      ...applications[key],
    }));

    if (thunkAPI.extra.experiments.enabled('specs.groups.EnableFilesTab')) {
      apps.push({
        key: GroupAppKey.FILES_APP,
        installed: false,
        ...applications[GroupAppKey.FILES_APP],
      });
    }

    if (thunkAPI.extra.experiments.enabled('specs.groups.Enable3CustomTabs')) {
      apps.push({
        key: GroupAppKey.CUSTOM_APP_2,
        ...applications[GroupAppKey.CUSTOM_APP_2],
      });
      apps.push({
        key: GroupAppKey.CUSTOM_APP_3,
        ...applications[GroupAppKey.CUSTOM_APP_3],
      });
    }

    return {
      apps,
      group,
    };
  },
  {
    hideErrorMessage: is404,
    formatError(t, arg) {
      return t('groups-web.toast.error.group.fetch', {
        slug: arg.groupIdOrSlug,
      });
    },
  },
);

export const updateQuestions = createAsyncThunk(
  'questions:update',
  async function (
    params: CreateOrReplaceAllMembershipQuestionsRequest,
    thunkAPI,
  ) {
    const { translations } = thunkAPI.extra;

    const { data } = await thunkAPI.extra.httpClient.request(
      createOrReplaceAllMembershipQuestions(params),
    );

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        message: translations.t('groups-web.toast.membership-questions-saved'),
      }),
    );

    return data.questions ?? [];
  },
);

export const fetchQuestions = createAsyncThunk(
  'questions:fetch',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      listMembershipQuestions({
        groupId,
      }),
    );

    return data.questions ?? [];
  },
);

export const fetchJoinRequirements = createAsyncThunk(
  'group:fetchJoinRequirements',
  async function (
    params: GetJoinRequirementsRequest,
    thunkAPI,
  ): Promise<IJoinRequirements> {
    let eventsResponse;

    const { data } = await thunkAPI.extra.httpClient.request(
      getJoinRequirements(params),
    );

    const eventIds = data.violation?.eventsOptions?.eventIds;

    if (eventIds) {
      eventsResponse = await thunkAPI.extra.httpClient.request(
        queryEventsV2({
          facet: ['status'],
          fieldset: [
            EventFieldset.DETAILS,
            EventFieldset.REGISTRATION,
            EventFieldset.URLS,
          ],
          query: {
            sort: [{ fieldName: 'start', order: SortOrder.ASC }],
            paging: {
              limit: 100,
            },
            filter: {
              eventId: {
                $hasSome: eventIds,
              },
              status: {
                $ne: 'CANCELED',
              },
            },
          },
        }),
      );
    }

    return {
      ...data,
      violation: {
        ...(data.violation || {}),
        eventsOptions: {
          events: eventsResponse?.data?.events || [],
        },
      },
    };
  },
);

export const join = createAsyncThunk(
  'group:join',
  async function (params: JoinRequest, thunkAPI) {
    const { dispatch, extra } = thunkAPI;
    const { httpClient, translations } = extra;
    const { t } = translations;

    const user = selectCurrentUser(thunkAPI.getState());

    if (!user.loggedIn) {
      try {
        await thunkAPI.dispatch(application.thunks.login()).unwrap();
      } catch {
        return thunkAPI.rejectWithValue(false);
      }
    }

    const agreedToShareProfile = selectShareProfileConsent(
      thunkAPI.getState(),
      params.groupId,
    );

    const requirements = await dispatch(fetchJoinRequirements(params)).unwrap();

    switch (requirements.violation?.violationType) {
      case ViolationType.ALREADY_JOINED:
        dispatch(application.actions.closeAllDialogs());
        return thunkAPI.rejectWithValue(false);

      case ViolationType.NONE:
        if (!agreedToShareProfile) {
          dispatch(
            application.actions.showDialog({
              params,
              dialog: 'disclaimer',
            }),
          );

          return thunkAPI.rejectWithValue(false);
        }

        const joinResponse = await httpClient.request(
          membership.joinGroup(params),
        );

        dispatch(application.actions.closeAllDialogs());
        dispatch(
          application.actions.showToast({
            type: 'success',
            message: t('groups-web.toast.group-joined', {
              groupName: joinResponse.data.group.name,
            }),
          }),
        );
        return joinResponse.data.group;

      case ViolationType.ADMIN_APPROVAL:
        if (!agreedToShareProfile) {
          dispatch(
            application.actions.showDialog({
              params,
              dialog: 'disclaimer',
            }),
          );

          return Promise.reject();
        }

        const requestJoinResponse = await httpClient.request(
          membership.requestJoinGroup(params),
        );

        dispatch(application.actions.closeAllDialogs());
        dispatch(
          application.actions.showToast({
            type: 'success',
            message: t('groups-web.toast.group-request-to-join', {
              groupName: requestJoinResponse.data.group.name,
            }),
          }),
        );

        return requestJoinResponse.data.group;

      case ViolationType.MEMBERSHIP_QUESTIONS:
        if (!agreedToShareProfile) {
          dispatch(
            application.actions.showDialog({
              params,
              dialog: 'disclaimer',
            }),
          );

          return thunkAPI.rejectWithValue(false);
        }

        dispatch(
          application.actions.showDialog({
            params,
            dialog: 'groupQuestions',
          }),
        );
        return thunkAPI.rejectWithValue(false);

      case ViolationType.EVENTS:
        dispatch(
          application.actions.showDialog({
            params,
            dialog: 'eventsRestriction',
          }),
        );
        return thunkAPI.rejectWithValue(false);

      case ViolationType.PRICING_PlANS:
        dispatch(
          application.actions.showDialog({
            params,
            dialog: requirements.violation?.pricingPlansOptions?.futurePlans
              ?.length
              ? 'futurePlanDialog'
              : 'paidPlansRestriction',
          }),
        );
        return thunkAPI.rejectWithValue(false);

      case ViolationType.NOT_LOGGED_IN:
      case ViolationType.SECRET_GROUP:
      default:
        return thunkAPI.rejectWithValue(false);
    }
  },
);

export const leave = createAsyncThunk(
  'group:leave',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      membership.leaveGroup(groupId),
    );

    return data.group;
  },
);

export const remove = createAsyncThunk(
  'group:delete',
  async function (groupId: string, thunkAPI) {
    const flowApi = thunkAPI.extra;
    const { t } = flowApi.translations;

    const groupName = selectGroupName(thunkAPI.getState(), groupId) ?? '';

    const { data } = await flowApi.httpClient.request(deleteGroup({ groupId }));

    thunkAPI.dispatch(
      application.actions.showToast({
        type: 'success',
        message: t('groups-web.group.actions.delete.success', {
          groupName,
        }),
      }),
    );

    return data;
  },
);

export const cancelRequest = createAsyncThunk(
  'group:cancelRequest',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      membership.cancelRequest(groupId),
    );

    return data.group;
  },
);

export const fetchNotificationSettings = createAsyncThunk(
  'group:fetchNotificationSettings',
  async function (params: GetNotificationSettingsRequest, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      notificationSettings.getNotificationSettings(params),
    );

    return data.settings || [];
  },
);

export const updateNotificationSettings = createAsyncThunk(
  'group:updateNotificationSettings',
  async function (params: UpdateNotificationSettingsRequest, thunkAPI) {
    await thunkAPI.extra.httpClient.request(
      notificationSettings.updateNotificationSettings(params),
    );

    return params.settings || [];
  },
);
